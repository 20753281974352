import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../api/api';

import { setShareId } from '../../redux/share/share.slice';
import { useDispatch } from 'react-redux';
const SharePage = () => {
  const { shareId } = useParams();
  const navigate = useNavigate();
  const [shareData, setShareData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the share data from the server

    dispatch(setShareId(shareId));

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v0/share/${shareId}`)
      .then((response) => {
        setShareData(response.data);

        // Track that the link was opened
        api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/share/track-open`, { shareId });
      })
      .catch(() => {
        navigate(`${process.env.REACT_APP_API_BASE_URL}404`);
      });
  }, [shareId, navigate]);
  useEffect(() => {
    if (shareData) {
      const { redirectUrl } = shareData.data;
      if (redirectUrl) {
        try {
          // Parse the URL to extract the relative path
          const appUrl = new URL(redirectUrl);

          const relativePath = appUrl.pathname + appUrl.search + appUrl.hash;
          navigate(relativePath);
        } catch (error) {
          console.error('Invalid redirect URL', error);
        }
      }
    }
  }, [shareData, navigate]);

  if (!shareData) return <div>Loading...</div>;

  return (
    <div>
      {/* Helmet allows setting custom meta tags for SEO and social media previews */}
      <Helmet>
        <title>{shareData.data.metaData.title}</title>
        <meta property="og:title" content={shareData.data.metaData.title} />
        <meta property="og:description" content={shareData.data.metaData.description} />
        <meta property="og:image" content={shareData.data.metaData.imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${window.location.origin}/share/${shareId}`} />
      </Helmet>
      {/* Render share content or redirect based on entity */}
      <h1>{shareData.data.metaData.title}</h1>
      <p>{shareData.data.metaData.description}</p>
      {/* Optionally redirect based on entity type */}
    </div>
  );
};

export default SharePage;
