import React, { useState } from 'react';
import './SignInpopup.css';
import BottomSheet from '../ui/BottomSheet';
import { Height } from '@mui/icons-material';
import { toggleAuthWallOff, toggleAuthWallOn } from '../../redux/auth/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import SocialLogin from './SocialLogin';

const AuthWall = ({ isVisible }) => {
  const dispatch = useDispatch();
  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const [open, setOpen] = useState(isVisible);

  const { isAuthenticated } = useSelector((state) => state.auth);
  const handleCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  React.useEffect(() => {
    setOpen(isVisible);
  }, [isVisible]);
  const closeHandler = (e) => {
    dispatch(toggleAuthWallOff());
  };
  return (
    <div className={`popup-container ${isVisible ? 'visible' : ''}`}>
      <BottomSheet open={open} setOpen={setOpen} closeHandler={closeHandler} isAuthenticated={isAuthenticated}>
        <div className="w-full max-w-md flex flex-col mx-auto px-4">
          <span className={`self-center mb-3 ${!isTermsChecked ? 'opacity-50 pointer-events-none' : ''}`}>
            <SocialLogin />
          </span>

          <div className="flex flex-col items-center text-center space-y-2">
            <div className="flex items-center space-x-2">
              <input
                id="terms"
                type="checkbox"
                checked={isTermsChecked}
                onChange={handleCheckboxChange}
                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="terms" className="text-sm text-gray-600">
                I agree to Gully Ball&aposs
                <a href="/terms-of-service" className="text-blue-600 underline hover:text-blue-800">
                  Terms of services
                </a>{' '}
                and{' '}
                <a href="/privacy-policy" className="text-blue-600 underline hover:text-blue-800">
                  Privacy policy
                </a>
                .
              </label>
            </div>
            <p className="text-xs text-gray-500">
              By agreeing to the above terms, you are consenting that your personal information will be collected, stored, and
              processed.
            </p>
            <p className="text-xs text-gray-500">
              If deletion requested, check status{' '}
              <a href="/deletion-status" className="text-blue-600 underline hover:text-blue-800">
                here
              </a>
              .
            </p>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default AuthWall;
