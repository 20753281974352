import { Box, Card, Chip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Instagram from './../assets/direct.png';
import { useDispatch } from 'react-redux';
import {
  setCurrentPlayingVideo,
  setSelectedIndividualHighlightClip,
  setSelectedSummaryOrFullMatchVideo,
} from '../redux/video/video.slice';
import './css/VideoListItem.css';

const VideoListItem = ({ clip, isPlaying }) => {
  const eventChipContentsMapV1 = {
    'Four Runs': { text: '4', chipBgColor: '#7BC8FB' },
    'Six Runs': { text: '6', chipBgColor: '#6FCF97' },
    'Three Runs': { text: '3', chipBgColor: '#7BC8FB' },
    Wicket: { text: 'W', chipBgColor: '#DF513D' },
    'Zero Run': { text: '0', chipBgColor: '#DF513D' },
    'One Run': { text: '1', chipBgColor: '#7BC8FB' },
    'Two Runs': { text: '2', chipBgColor: '#7BC8FB' },
    'Dot Ball': { text: 'Dot', chipBgColor: '#7BC8FB' },
    'Five Runs': { text: '5', chipBgColor: '#7BC8FB' },
    'Wide Ball': { text: 'WD', chipBgColor: '#7BC8FB' },
    'No Ball': { text: 'NB', chipBgColor: '#7BC8FB' },
  };
  const eventChipContentsMapV0 = {
    Four: { text: '4', chipBgColor: '#7BC8FB' },
    Six: { text: '6', chipBgColor: '#6FCF97' },
    3: { text: '3', chipBgColor: '#7BC8FB' },
    Wicket: { text: 'W', chipBgColor: '#DF513D' },
  };
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let score = null;
  let event = clip?.event;
  let clipCaptionText = clip?.videoCategory && clip.caption.split(' | ')[1];
  let displayCaption = clip?.videoCategory && clip?.displayCaption;
  let chipBgColor = clip?.event && (eventChipContentsMapV1[clip?.event]?.['chipBgColor'] || '#7BC8FB');
  let chipText = clip?.event && (eventChipContentsMapV1[clip?.event]?.['text'] || clip?.event);
  let Inning = clip?.inning === '1st Innings' ? 'Inn 1' : clip?.inning === '2nd Innings' ? 'Inn 2' : clip?.inning;
  let scoreChipText = `${clip?.curr_score} | ${Inning || ''}`;
  // for prev versions
  if (!clip?.videoCategory) {
    const captionSplit = clip?.caption.split('|');
    score = captionSplit[0];
    clipCaptionText = captionSplit[1];
    event = clipCaptionText.trim().split(' ')[0];
    chipBgColor = eventChipContentsMapV0[event]['chipBgColor'];
    chipText = eventChipContentsMapV0[event]['text'];
    scoreChipText = score;
  }
  const handleCopyVideoUrl = (url) => {
    console.log(`Copied video URL: ${url}`);
  };
  const handleCardClick = (clip) => {
    if (!clip?.videoCategory || clip.videoCategory === 'INDIVIDUAL_HIGHLIGHT_CLIP') {
      dispatch(setSelectedIndividualHighlightClip(clip));
      dispatch(
        setCurrentPlayingVideo({
          url: clip.clipS3Url,
          category: clip.videoCategory,
          caption: displayCaption,
        })
      );
    } else {
      dispatch(setSelectedSummaryOrFullMatchVideo(clip));
      dispatch(
        setCurrentPlayingVideo({
          url: clip.mediaUrl,
          category: clip.videoCategory,
          caption: clipCaptionText,
        })
      );
    }
  };
  // Extract the 'webview' parameter from the URL
  const isWebView = searchParams.get('webview') === 'true';

  return (
    <Card className={`card ${isPlaying ? '-playing' : ''}`} variant="outlined" onClick={() => handleCardClick(clip)}>
      <div className={`thumbnail-container ${isPlaying ? '-blur' : ''}`}>
        {isPlaying ? <Typography className="now-playing-text">PLAYING NOW</Typography> : <div className="play-icon"></div>}
        <img
          src="https://media.istockphoto.com/id/502037149/photo/cricket-action.jpg?s=612x612&w=0&k=20&c=zKWIx8c-5BGepNLpmWsQOoaNkZCEAdLr3KT_r8Y8maI="
          alt="Thumbnail"
          className="thumbnail"
        />
      </div>
      <div className="details">
        <div className="chipContainer">
          {!clip?.videoCategory || clip.videoCategory === 'INDIVIDUAL_HIGHLIGHT_CLIP' ? (
            <>
              <Chip
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: chipBgColor,
                  borderRadius: '5px',
                  fontFamily: 'Roboto',
                  fontWeight: '700',
                  fontSize: '1.8vh',
                }}
                className="video-list-eventChip"
                label={chipText}
              />
              <Box>
                <Chip
                  className="video-list-scoreChip"
                  sx={{
                    color: '#FFFFFF',
                    backgroundColor: '#000000',
                    borderRadius: '5px',
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '1.3vh',
                  }}
                  label={scoreChipText}
                />
              </Box>
              <div className="shareIcon">
                {clip?.videoCategory === 'INDIVIDUAL_HIGHLIGHT_CLIP' && isWebView && (
                  <img
                    src={Instagram}
                    alt="Instagram Icon"
                    onClick={() => handleCopyVideoUrl(clip?.clipS3Url)}
                    style={{
                      color: '#E1306C',
                      cursor: 'pointer',
                      height: '2.6vh',
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <Chip
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#000000',
                borderRadius: '5px',
                fontFamily: 'Roboto',
                fontWeight: '500',
                fontSize: '10px',
              }}
              className=""
              label={clip?.videoCategory === 'HIGHLIGHT_SUMMARY' ? 'SUMMARY VIDEO' : 'FULL MATCH VIDEO'}
            />
          )}
        </div>
        {!clip?.videoCategory || clip.videoCategory === 'INDIVIDUAL_HIGHLIGHT_CLIP' ? (
          <Typography
            sx={{
              marginLeft: '10px',
              marginRight: '25px',
              fontFamily: 'Roboto',
              fontWeight: '500',
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              fontSize: '1.7vh',
            }}
          >
            {displayCaption}
          </Typography>
        ) : (
          <Typography
            sx={{
              marginTop: '0px',
              marginLeft: '5px',
              fontFamily: 'Roboto',
              fontWeight: '500',
              fontSize: '1.7vh',
            }}
          >
            {clip?.caption}
          </Typography>
        )}
      </div>
    </Card>
  );
};
export default VideoListItem;
