import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { RWebShare } from 'react-web-share';
import { IoShareSocialOutline } from 'react-icons/io5';
import { MdOutlineVisibility } from 'react-icons/md';
import AvatarImg from '../../assets/Avatar-Image.svg';
import VideoPlayer from '../player';
import LiveButton from './Live';
import NoDataComponent from '../NoDataComponent';
import fireIcon from '../../assets/fire.png';
import '../css/matchcard.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIdentifier } from '../../helpers/userUuid';
import { generateShareId } from '../../redux/share/share.action';
import { setShareId, setShareURL } from '../../redux/share/share.slice';
import ReactPlayer from 'react-player';

const MatchCard = ({ matches }) => {
  const totalMatches = matches.length || null;
  const { groupedMatches, unGroupedMatches } = groupMatchesByTournament(matches);

  return (
    <>
      {totalMatches === null ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <NoDataComponent label="NO MATCHES AVAILABLE" />
        </Box>
      ) : (
        <>
          <div className="match-count" style={{ textAlign: 'left', marginLeft: '0' }}>
            {`Showing ${totalMatches} matches`}
          </div>
          {Object.keys(groupedMatches).map((key) => {
            const { tournamentName, matches } = groupedMatches[key];
            return (
              <div className="match-cards-container" key={key}>
                <div className="tournament-group">
                  <div className="tournament-title">
                    <img src={AvatarImg} alt="Avatar" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                    {tournamentName.length > 25 ? `${tournamentName.slice(0, 25)}...` : tournamentName}
                  </div>
                </div>
                {matches.map((match, index) => (
                  <SingleMatchCard key={index} config={match} />
                ))}
              </div>
            );
          })}
          {unGroupedMatches.map((match, index) => (
            <SingleMatchCard key={index} config={match} />
          ))}
        </>
      )}
    </>
  );
};

export default MatchCard;

const groupMatchesByTournament = (matches = []) => {
  return matches.reduce(
    (acc, match) => {
      const tournamentId = match?.tournament?.tournamentId;
      const tournamentName = match?.tournament?.tournamentName;

      if (!tournamentId || !tournamentName) {
        acc.unGroupedMatches.push(match);
      } else {
        const key = `${tournamentId}-${tournamentName}`;
        if (!acc.groupedMatches[key]) {
          acc.groupedMatches[key] = {
            tournamentName,
            matches: [],
          };
        }
        acc.groupedMatches[key].matches.push(match);
      }

      return acc;
    },
    { groupedMatches: {}, unGroupedMatches: [] }
  );
};

const SingleMatchCard = ({ config }) => {
  const { date, matchStatus, topLabels, matchId, matchResult, engagement, medias, topChip, tournament } = config;
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  const hasScore = topLabels?.[0]?.score !== null && topLabels?.[1]?.score !== null;
  const videoMedia = medias;
  const isLive = topChip?.LiveMatch;
  const views = engagement?.views || null;

  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { shareId, shareURL } = useSelector((state) => state.share);
  const rWebShareRef = useRef(null); // Ref to trigger RWebShare click programmatically

  useEffect(() => {
    if (shareId) {
      dispatch(setShareURL(`${process.env.REACT_APP_API_BASE_URL}share/match/${shareId}`));
      dispatch(setShareId(null));
    }
  }, [shareId, dispatch]);
  useEffect(() => {
    if (shareURL && rWebShareRef.current) {
      rWebShareRef.current.click();
      dispatch(setShareURL(null));
    }
  }, [shareURL, dispatch]);

  const buildSharePayload = (matchId) => {
    let payload = {
      entityType: 'match',
      entityId: matchId,
      platform: 'whatsapp',
      matchId: matchId,
    };
    if (isAuthenticated) {
      payload['userId'] = user.userObjId;
    } else {
      payload['guestId'] = getUserIdentifier();
    }
    dispatch(generateShareId(payload));
  };

  const handlePlayPause = (playing) => {
    setIsPlaying(playing);
  };

  const handleClick = () => {
    navigate(`/match/${matchId}`);
  };

  const truncateFirstWord = (result) => {
    if (!result) return '';
    const words = result.split(' ');
    if (words[0].length > 10) {
      words[0] = `${words[0].slice(0, 10)}.`;
    }
    return words.join(' ');
  };

  return (
    <>
      {/* {isLive && matchId === 'zrmtouj8yf' && (
        <div className="match-card " style={{ position: 'relative' }}>
          {isLive && <LiveButton />}
          <div
            className="video-card"
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            <ReactPlayer
              className="player"
              url={'https://youtube.com/live/P3h9FDwaf20?feature=share'}
              playing={true}
              controls={true}
              width="100%"
              height="100%"
            />
            {!isPlaying && (
                  <div>
                    <div className="overlay-header">
                      <div className="team-info">
                        <div className="team">
                          <span className="team-name">
                            {topLabels?.[0]?.name?.length > 12 ? `${topLabels[0].name.slice(0, 12)}.` : topLabels?.[0]?.name}
                          </span>
                          <span className="score">{topLabels?.[0]?.score}</span>
                        </div>
                        <span className="vs">vs</span>
                        <div className="team">
                          <span className="team-name">
                            {topLabels?.[1]?.name?.length > 12 ? `${topLabels[1].name.slice(0, 12)}.` : topLabels?.[1]?.name}
                          </span>
                          <span className="score">{topLabels?.[1]?.score}</span>
                        </div>
                      </div>
                    </div>
    
                    <div className="date-status">
                      <span className="date">{date}</span>
                      <span className="separator">|</span>
                      <span className="status">{matchStatus}</span>
                    </div>
                  </div>
                )}
          </div>
          <div
            className="match-footer1"
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="team-info">
                {isPlaying ? (
                  <>
                    <div className="team">
                      <span className="team-name">
                        {topLabels?.[0]?.name?.length > 12 ? `${topLabels[0].name.slice(0, 12)}.` : topLabels?.[0]?.name}
                      </span>
                      <span className="score">{topLabels?.[0]?.score}</span>
                    </div>
                    <span className="vs">vs</span>
                    <div className="team">
                      <span className="team-name">
                        {topLabels?.[1]?.name?.length > 12 ? `${topLabels[1].name.slice(0, 12)}.` : topLabels?.[1]?.name}
                      </span>
                      <span className="score">{topLabels?.[1]?.score}</span>
                    </div>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    {matchResult && (
                      <span className="match-result" style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                        <img src={fireIcon} alt="fire icon" style={{ width: '14px', height: '14px', marginRight: '5px' }} />
                        {truncateFirstWord(matchResult)}
                      </span>
                    )}
                  </Box>
                )}
              </div>
              <Box className="mr-5" sx={{ display: 'flex', alignItems: 'center', columnGap: 0.5 }}>
                {views && (
                  <>
                    <MdOutlineVisibility sx={{ fontSize: '15px', cursor: 'pointer' }} />
                    <span className="view">{views}</span>
                  </>
                )}
                <RWebShare
                  data={{
                    text: 'Lets Watch together',
                    url: window.location.href,
                    title: 'GullyBall',
                  }}
                >
                  <IoShareSocialOutline sx={{ fontSize: '12px', cursor: 'pointer' }} />
                </RWebShare>
              </Box>
            </Box>
          </div>
        </div>
      )} */}

      
      {videoMedia && (
        <div className="match-card " style={{ position: 'relative' }}>
          {isLive && <LiveButton />}
          <div
            className="video-card"
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            <VideoPlayer videoUrl={videoMedia} onPlayPause={handlePlayPause} />
            {!isPlaying && (
              <div>
                <div className="overlay-header">
                  <div className="team-info">
                    <div className="team">
                      <span className="team-name">
                        {topLabels?.[0]?.name?.length > 12 ? `${topLabels[0].name.slice(0, 12)}.` : topLabels?.[0]?.name}
                      </span>
                      <span className="score">{topLabels?.[0]?.score}</span>
                    </div>
                    <span className="vs">vs</span>
                    <div className="team">
                      <span className="team-name">
                        {topLabels?.[1]?.name?.length > 12 ? `${topLabels[1].name.slice(0, 12)}.` : topLabels?.[1]?.name}
                      </span>
                      <span className="score">{topLabels?.[1]?.score}</span>
                    </div>
                  </div>
                </div>

                <div className="date-status">
                  <span className="date">{date}</span>
                  <span className="separator">|</span>
                  <span className="status">{matchStatus}</span>
                </div>
              </div>
            )}
          </div>
          <div className="match-footer">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                {matchResult && (
                  <span className="match-result" style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                    <img
                      src={fireIcon}
                      alt="fire icon"
                      style={{ width: '14px', height: '14px', marginRight: '5px', borderRadius: '50%' }}
                    />
                    {truncateFirstWord(matchResult)}
                  </span>
                )}
              </Box>
              <Box className="mr-5" sx={{ display: 'flex', columnGap: 0.5 }}>
                {views && (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0.5, cursor: 'pointer' }}>
                    <MdOutlineVisibility sx={{ fontSize: '15px' }} />
                    <span className="view">{views}</span>
                  </Box>
                )}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1px', marginRight: '6px', cursor: 'pointer' }}
                  onClick={() => buildSharePayload(config.matchId)}
                >
                  <IoShareSocialOutline sx={{ fontSize: '12px' }} />
                </Box>
                <Box className="hidden">
                  <RWebShare
                    data={{
                      text: 'Lets Watch together',
                      url: shareURL,
                      title: 'Gully Cricket',
                    }}
                    sites={['facebook', 'twitter', 'whatsapp', 'reddit', 'linkedin', 'mail']}
                  >
                    <span ref={rWebShareRef} />
                  </RWebShare>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      )}

      {!videoMedia && (
        <div className="match" style={{ position: 'relative' }}>
          {isLive && <LiveButton />}
          <div className="match-header" style={{ cursor: 'pointer' }} onClick={handleClick}>
            <div className="left-section">
              <div className="date-status1">
                <span className="date1">{date}</span>
                <span className="separator1 "></span>
                <span className="status1">{matchStatus}</span>
              </div>
              <div className={`info ${hasScore ? 'vertical-layout' : 'horizontal-layout'}`}>
                {hasScore ? (
                  <>
                    <div className="team">
                      <span className="T1 text-[12px]">
                        {topLabels?.[0]?.name?.length > 15 ? `${topLabels[0].name.slice(0, 15)}.` : topLabels?.[0]?.name}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="vs1">vs</span>
                      <div className="separator-black"></div>
                    </div>

                    <div className="team">
                      <span className="T1 text-[12px]">
                        {topLabels?.[1]?.name?.length > 15 ? `${topLabels[1].name.slice(0, 15)}.` : topLabels?.[1]?.name}
                      </span>
                    </div>
                  </>
                ) : (
                  <span className="single-line">
                    <span className="T2 text-[12px]">
                      {topLabels?.[0]?.name?.length > 12 ? `${topLabels[0].name.slice(0, 12)}.` : topLabels?.[0]?.name}
                    </span>
                    <span className="vs2 text-[10px]">vs</span>
                    <span className="T2 text-[12px]">
                      {topLabels?.[1]?.name?.length > 12 ? `${topLabels[1].name.slice(0, 12)}.` : topLabels?.[1]?.name}
                    </span>
                  </span>
                )}
              </div>
            </div>
            {hasScore && (
              <div className="score-display">
                <span className="score text-[12px]">
                  {topLabels?.[0]?.score}
                  <span className="overs">
                    {topLabels?.[0]?.overplayed !== null && topLabels?.[0]?.overplayed !== undefined
                      ? `${topLabels[0].overplayed}/${topLabels[0].totalover} ov`
                      : 'yet to bat'}
                  </span>
                </span>
                <div className="separator-black1"></div>
                <span className="score text-[12px]">
                  {topLabels?.[1]?.score}
                  <span className="overs">
                    {topLabels?.[1]?.overplayed !== null && topLabels?.[1]?.overplayed !== undefined
                      ? `${topLabels[1].overplayed}/${topLabels[1].totalover} ov`
                      : 'yet to bat'}
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className="match-footer">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                {matchResult && (
                  <span className="match-result" style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                    <img
                      src={fireIcon}
                      alt="fire icon"
                      style={{ width: '14px', height: '14px', marginRight: '5px', borderRadius: '50%' }}
                    />
                    {truncateFirstWord(matchResult)}
                  </span>
                )}
              </Box>
              <Box className="mr-5" sx={{ display: 'flex', columnGap: 0.5 }}>
                {views && (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0.5, cursor: 'pointer' }}>
                    <MdOutlineVisibility sx={{ fontSize: '15px' }} />
                    <span className="view">{views}</span>
                  </Box>
                )}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1px', marginRight: '6px', cursor: 'pointer' }}
                  onClick={() => buildSharePayload(config.matchId)}
                >
                  <IoShareSocialOutline sx={{ fontSize: '12px' }} />
                </Box>
                <Box className="hidden">
                  <RWebShare
                    data={{
                      text: 'Lets Watch together',
                      url: shareURL || `${process.env.REACT_APP_API_BASE_URL}share/match/${shareId}`,
                      title: 'Gully Cricket',
                    }}
                    sites={['facebook', 'twitter', 'whatsapp', 'reddit', 'linkedin', 'mail']}
                  >
                    <span ref={rWebShareRef} />
                  </RWebShare>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};
