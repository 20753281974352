import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';
import BatIcon from './ui/BatIcon';
import UserIcon from './ui/UserIcon';
import Infoicon from '../assets/info.png';
import Privacyicon from '../assets/padlock.png';
import gullyLogo from '../assets/gully_logo.png';
import { useLocation } from 'react-router-dom';

const MainWidthWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        fontFamily: 'Arial, sans-serif',
        mx: 'auto',
        width: { md: '55%', sm: '100%' },
      }}
    >
      {children}
    </Box>
  );
};
const Navbar = ({ onBatClick, onAvatarClick, onlogoClick, onInfoclick, onPrivacyClick }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [selectedPage, setSelectedPage] = useState(() => location.pathname);

  useEffect(() => {
    setSelectedPage(location.pathname);
  }, [location]);

  const isWebView = searchParams.get('webview') === 'true';

  return (
    <MainWidthWrapper>
      <div className="h-[50px] flex justify-between p-[10px] bg-white">
        {/* Logo */}
        <div className="w-[190px] flex items-center" onClick={onlogoClick}>
          <img src={gullyLogo} alt="Gully Logo" className="w-[180px] md:w-[175px] h-9 cursor-pointer" />
        </div>

        {/* Icons */}
        <div className="flex gap-[5px] md:gap-[10px] items-center h-full cursor-pointer">
          {/* Bat Icon */}
          {selectedPage !== '/' && (
            <div className={`${selectedPage === '/' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`} onClick={onBatClick}>
              <BatIcon fillColor={selectedPage === '/' ? '#FFCB05' : '#0d1b2a'} />
            </div>
          )}
          {/* Info Icon */}
          {selectedPage !== '/about-us' && (
            <div
              className={`${selectedPage === '/about-us' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
              onClick={onInfoclick}
            >
              <img
                src={Infoicon}
                alt="Info Icon"
                style={{
                  color: '#E1306C',
                  cursor: 'pointer',
                  height: '2.6vh',
                }}
              />
            </div>
          )}
          {/* Privacy Icon */}
          {selectedPage !== '/privacy-policy' && (
            <div
              className={`${selectedPage === '/privacy-policy' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
              onClick={onPrivacyClick}
            >
              <img
                src={Privacyicon}
                alt="Privacy Icon"
                style={{
                  color: '#E1306C',
                  cursor: 'pointer',
                  height: '2.5vh',
                }}
              />
            </div>
          )}
          {/* User Icon */}
          {!isWebView && selectedPage !== '/privacy-policy' && selectedPage !== '/about-us' && (
            <div
              className={`${selectedPage === '/user-profile' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
              onClick={onAvatarClick}
            >
              <UserIcon
                fillColor={selectedPage === '/user-profile' ? '#FFCB05' : '#0d1b2a'}
                backColor={selectedPage === '/user-profile' ? '#BA3C3D' : '#F1F5F9'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-[2px] bg-[#F2F4F5]"></div>
    </MainWidthWrapper>
  );
};

export default Navbar;
