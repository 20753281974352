import React from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BottomSheet = ({ children, open, setOpen, closeHandler, isAuthenticated }) => {
  const handleDrawerClose = (event) => {
    if (isAuthenticated) {
      setOpen(false);
      if (closeHandler) {
        closeHandler(event);
      }
    } else {
      event.preventDefault(); // Block closing for unauthenticated users
      console.log('User must be logged in to close this drawer');
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={(event) => handleDrawerClose(event)}
      ModalProps={{
        keepMounted: true,
        onBackdropClick: (event) => handleDrawerClose(event), // Unified logic for backdrop clicks
      }}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          padding: '10px',
          height: '176px',
          maxHeight: '80vh',
          width: 'auto',
          maxWidth: '100vw',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Close Button Section */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
  
        </Box>

        {/* Content Section */}
        <Box sx={{ height: '5px', padding: '30px' }}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default BottomSheet;
