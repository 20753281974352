import React, { useState } from 'react';
import MainWidthWrapper from './MainWidthWrapper';
import api from '../api/api';

const RequestPage = () => {
  const [requestMessage, setRequestMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async () => {
    if (requestMessage.trim() === '') {
      setStatus('Failed: Message cannot be empty');
      return;
    }
    setStatus('Submitting...');
    try {
      const response = await api.get(`/api/v0/request/${encodeURIComponent(requestMessage)}`);
      setStatus(response?.data?.data?.status);
    } catch (error) {
      console.error('Error:', error);
      setStatus('enter valid Request Id ');
    }
  };

  return (
    <MainWidthWrapper>
      <div className="w-full h-full p-10 ">
        <div className="bg-[#edede9] w-[100%] border rounded-lg mx-auto flex flex-col items-center gap-12 py-16 my-[25%] ">
          <div>
            <input
              id="longTextInput"
              value={requestMessage}
              onChange={(e) => setRequestMessage(e.target.value)}
              placeholder="Request Id"
              rows={10}
              cols={50}
              style={{
                width: '100%',
                maxWidth: '600px',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <div>
            <button
              onClick={handleSubmit}
              className="w-full sm:w-auto px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Check
            </button>
          </div>
          {status && (
            <>
              <div>Status: {status}</div>
            </>
          )}
        </div>
      </div>
    </MainWidthWrapper>
  );
};

export default RequestPage;
