import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import OverTicker from './overs';
import ballIcon from './../assets/ball.png';

const OverDetails = ({ inningId }) => {
  const { RunsPerOver, rpo, isLive } = useSelector((state) => state.match);
  const matchData = useSelector((state) => state.match);
  const currScore = matchData.currScore;
  const ballbyballdetails = rpo?.ovballByBallDetails;
  const dataSource = isLive && ballbyballdetails ? ballbyballdetails : RunsPerOver;
  const currentInning = dataSource.find((inning) => inning.inningId === String(inningId));
  const currentInningOvers = currentInning.overs || [];
  const ProfileDetails = matchData?.profileName;
  const PlayerName = currScore?.playerProfiles;

  if (!RunsPerOver || !Array.isArray(RunsPerOver)) {
    return null;
  }

  if (!currentInning) {
    return null;
  }

  const styles = {
    textTransform: 'uppercase',
    LineHeight: '16px',
    gap: '0px',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '16px',
    fontcolor: '#000000',
    flex: 1,
  };

  const customstyles = {
    height: '28px',
    padding: '4px 0px 4px 2px',
    gap: '8px',
    border: '0px 0px 2px 0px',
    color: '#000000',
    backgroundColor: '#FFFFFF',
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#5B5757', marginRight: '4px' }} />
        <img src={ballIcon} alt="Cricket Ball" style={{ width: '15px', height: '15px', marginRight: '4px' }} />
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '15px',
            textAlign: 'center',
            color: '#5B5757',
            padding: '0px 2px',
          }}
        >
          OVER BY OVER DETAILS
        </Typography>
        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#5B5757', marginLeft: '4px' }} />
      </Box>
      {currentInningOvers
        .slice()
        .reverse()
        .map(
          (over, index) =>
            over?.balls?.[0].run !== '' && (
              <Box
                key={index}
                sx={{
                  margin: '16px',
                  padding: '12px 12px 8px 12px',
                  gap: '10px',
                  borderRadius: '15px 15px 15px 15px',
                  border: '1px solid #C8CDF9',
                  background: 'linear-gradient(180deg, rgba(235, 234, 252, 0.52) 0%, rgba(235, 234, 252, 0) 100%)',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Box>
                    <Typography sx={{ fontWeight: 500, fontSize: '10px', lineHeight: '10px', color: '#64748B' }}>
                      Bowler
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 550, fontSize: '12px', lineHeight: '16px', color: '#0A0A0A', marginTop: '3px' }}
                    >
                      {over.bowlers && over.bowlers.length > 0
                        ? PlayerName?.[over.bowlers[0].name]?.name?.firstName ??
                          ProfileDetails?.[over.bowlers[0].name]?.name?.firstName
                        : null}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '10px', color: '#0A0A0A', textTransform: 'uppercase' }}
                    >
                      {currentInning?.teamName
                        ? currentInning.teamName.length > 15
                          ? `${currentInning.teamName.slice(0, 15)}...`
                          : currentInning.teamName
                        : '--'}
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: '12px', lineHeight: '14px', color: '#0A0A0A', marginTop: '3px' }}
                    >
                      {over.currRuns}/{over.currWkt}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    border: '1px solid #978FED',
                    marginTop: 0.5,
                    marginBottom: 2,
                  }}
                />
                <Typography
                  gutterBottom
                  sx={{
                    marginTop: 2,
                    fontWeight: '500',
                    fontSize: '10px',
                    lineHeight: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#64748B',
                  }}
                >
                  BALL BY BALL DETAILS
                </Typography>
                <OverTicker overData={[over]} />
                <Box sx={{ marginTop: 1 }}>
                  <TableContainer
                    component={Paper}
                    sx={{ bgcolor: '#EBEAFB10', border: 'none', borderColor: '#EBEAFB10', boxShadow: 'none' }}
                  >
                    <Table sx={{ border: 'none' }}>
                      <TableBody>
                        <TableRow sx={{ height: '45px' }}>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 1px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.runs}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                Runs
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 1px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.wickets}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                Wkts
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 4px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.fours}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                4&apos;s
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 4px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.sixes}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                6&apos;s
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 4px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.wideBalls}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                WD
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 4px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.noBalls}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                NB
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: '1px solid #E2E8F0', padding: '0px 4px', borderBottom: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '14px',
                                  textAlign: 'center',
                                  color: '#0A0A0A',
                                }}
                              >
                                {over.extras}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '10px',
                                  textAlign: 'center',
                                  color: '#64748B',
                                }}
                              >
                                Ex
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ marginTop: 2, gap: '6px' }}>
                  <TableContainer
                    component={Paper}
                    sx={{ bgcolor: '#EBEAFB10', padding: '0px 5px', borderRadius: 'none', boxShadow: 'none' }}
                  >
                    <Table sx={{ backgroundColor: '#EBEAFB10' }}>
                      <TableHead sx={{ height: '10px' }}>
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{
                              textAlign: 'center',
                              fontWeight: '500',
                              fontSize: '10px',
                              lineHeight: '10px',
                              height: '10px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                            }}
                          >
                            BATTER CONTRIBUTION
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%' }}>
                          <TableCell
                            sx={{
                              width: { xs: '50%', sm: '25%', md: '50%' },
                              height: '28px',
                              fontWeight: 500,
                              fontSize: '10px',
                              padding: '4px 0px',
                              gap: '8px',
                              borderBottom: '1px solid #978FED',
                              bgcolor: '#EBEAFB10',
                            }}
                          >
                            Batter
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '28px',
                              fontWeight: 500,
                              fontSize: '10px',
                              padding: '4px 2px',
                              gap: '8px',
                              border: '0px 0px 3px 0px',
                              borderBottom: '1px solid #F6CF7D',
                              textAlign: 'center',
                              bgcolor: '#EBEAFB10',
                            }}
                          >
                            Runs
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '28px',
                              fontWeight: 500,
                              fontSize: '10px',
                              padding: '4px 2px',
                              gap: '8px',
                              border: '0px 0px 3px 0px',
                              borderBottom: '1px solid #A4DDDC',
                              textAlign: 'center',
                              bgcolor: '#EBEAFB10',
                            }}
                          >
                            Balls
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '28px',
                              fontWeight: 500,
                              fontSize: '10px',
                              padding: '4px 2px',
                              gap: '8px',
                              border: '0px 0px 3px 0px',
                              borderBottom: '1px solid #7DADF6',
                              textAlign: 'center',
                              bgcolor: '#EBEAFB10',
                            }}
                          >
                            4&apos;s
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '28px',
                              fontWeight: 500,
                              fontSize: '10px',
                              padding: '4px 2px',
                              gap: '8px',
                              border: '0px 0px 3px 0px',
                              borderBottom: '1px solid #DDA4A4',
                              textAlign: 'center',
                              bgcolor: '#EBEAFB10',
                            }}
                          >
                            6&apos;s
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {over.batters &&
                          Object.keys(over.batters).map((key, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '4px',
                                width: '100%',
                              }}
                            >
                              <TableCell
                                sx={{
                                  ...customstyles,
                                  width: { xs: '50%', sm: '25%', md: '50%' },
                                  bgcolor: '#EBEAFB10',
                                  borderBottom: index === Object.keys(over.batters).length - 1 ? 'none' : '1px solid #E2E8F0',
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...styles,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {ProfileDetails?.[key]?.name?.firstName
                                    ? ProfileDetails[key].name.firstName.length > 15
                                      ? ProfileDetails[key].name.firstName.slice(0, 15) + '...'
                                      : ProfileDetails[key].name.firstName
                                    : PlayerName?.[key]?.name?.firstName
                                    ? PlayerName[key].name.firstName.length > 15
                                      ? PlayerName[key].name.firstName.slice(0, 15) + '...'
                                      : PlayerName[key].name.firstName
                                    : key}
                                </Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  ...customstyles,
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  bgcolor: '#EBEAFB10',
                                  borderBottom: index === Object.keys(over.batters).length - 1 ? 'none' : '1px solid #E2E8F0',
                                }}
                              >
                                <Typography sx={{ ...styles, textAlign: 'center' }}>{over.batters[key].runs}</Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  ...customstyles,
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  bgcolor: '#EBEAFB10',
                                  borderBottom: index === Object.keys(over.batters).length - 1 ? 'none' : '1px solid #E2E8F0',
                                }}
                              >
                                <Typography sx={{ ...styles, textAlign: 'center' }}>{over.batters[key].balls}</Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  ...customstyles,
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  bgcolor: '#EBEAFB10',
                                  borderBottom: index === Object.keys(over.batters).length - 1 ? 'none' : '1px solid #E2E8F0',
                                }}
                              >
                                <Typography sx={{ ...styles, textAlign: 'center' }}>{over.batters[key].fours}</Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  height: '28px',
                                  padding: '4px 0px 4px 2px',
                                  gap: '8px',
                                  border: '0px 0px 0px 0px',
                                  color: '#000000',
                                  bgcolor: '#EBEAFB10',
                                  borderBottom: index === Object.keys(over.batters).length - 1 ? 'none' : '1px solid #E2E8F0',
                                }}
                              >
                                <Typography sx={{ ...styles, textAlign: 'center' }}>{over.batters[key].sixes}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )
        )}
    </>
  );
};

export default OverDetails;
