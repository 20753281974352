import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

const BattingPartnershipTable = ({ inningId }) => {
  const { PartnershipRuns, parternerShips, isLive } = useSelector((state) => state.match);
  const matchData = useSelector((state) => state.match);
  const currScore = matchData.currScore;
  const parterner = parternerShips?.psh || null;

  const processPartnerships = (partnerships) => {
    if (!partnerships) return [];
    const inningPartnerships = partnerships[inningId];
    if (!inningPartnerships || !inningPartnerships.psh) return [];

    return inningPartnerships.psh
      .map((partnershipDetails, index) => {
        const batters = partnershipDetails.batters;
        if (!inningPartnerships) return [];

        const players = Object.keys(batters).filter((key) => key !== 'null');
        const player1 = players[0];
        const player2 = players[1] || '--';

        return {
          wicket: partnershipDetails.forWkt || '-',
          partnershipKey: `${inningId}-${index}`,
          player1,
          player2,
          runs: partnershipDetails.runs,
          balls: partnershipDetails.balls,
          player1Runs: batters[player1]?.runs || 0,
          player2Runs: batters[player2]?.runs || 0,
        };
      })
      .filter((partnership) => partnership !== null);
  };

  const partnershipsData = isLive && parterner ? parterner : PartnershipRuns;
  const partnerships = processPartnerships(partnershipsData);
  const ProfileDetails = matchData?.profileName;
  const PlayerName = currScore?.playerProfiles;
  const sortedPartnerships = partnerships.sort((a, b) => {
    if (a.wicket !== b.wicket) {
      return a.wicket - b.wicket;
    } else {
      return a.partnershipKey.localeCompare(b.partnershipKey);
    }
  });

  return (
    <Box
      sx={{
        margin: '16px',
        borderRadius: '13px',
        boxShadow: '6px 6px 16px 6px #14142B1C',
        overflowX: 'auto',
      }}
    >
      <TableContainer component={Paper} sx={{ padding: '4px 8px' }}>
        <Typography
          sx={{
            padding: 0.5,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '14.06px',
            letterSpacing: '0.5px',
            textAlign: 'left',
            color: '#515153',
          }}
        >
          BATTING PARTNERSHIPS
        </Typography>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%' }}>
              <TableCell
                sx={{
                  width: { xs: '10%', sm: '15%', md: '10%' },
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px',
                  borderBottom: '2px solid #DF513D',
                  textAlign: 'center',
                  bgcolor: '#FFFFFF',
                  height: '28px',
                }}
              >
                Wk
              </TableCell>
              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
              <TableCell
                sx={{
                  width: { xs: '25%', sm: '25%', md: '20%' },
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px',
                  borderBottom: '2px solid #978FED',
                  textAlign: 'left',
                  bgcolor: '#FFFFFF',
                  height: '28px',
                }}
              >
                Batter
              </TableCell>
              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
              <TableCell
                sx={{
                  width: { xs: '40%', sm: '30%', md: '30%' },
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px',
                  borderBottom: '2px solid #F6CF7D',
                  textAlign: 'center',
                  bgcolor: '#FFFFFF',
                  height: '28px',
                }}
              >
                Partnership runs
              </TableCell>
              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
              <TableCell
                sx={{
                  width: { xs: '25%', sm: '25%', md: '20%' },
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px',
                  borderBottom: '2px solid #978FED',
                  textAlign: 'right',
                  bgcolor: '#FFFFFF',
                  height: '28px',
                }}
              >
                Batter
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPartnerships.map((partnership, index) => (
              <TableRow key={index} sx={{ borderBottom: 'none', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%' }}>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      padding: '6px 2px',
                      fontSize: '10px',
                      fontWeight: 600,
                      width: { xs: '10%', sm: '15%', md: '10%' },
                      borderTop: '1px solid #E2E8F0',
                      borderBottom: 'none',
                      height: '28px',
                    }}
                  >
                    {partnership.wicket}
                  </TableCell>
                  <Box sx={{ width: '1px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      textAlign: 'left',
                      padding: '6px 2px',
                      fontSize: '10px',
                      fontWeight: 600,
                      width: { xs: '25%', sm: '25%', md: '20%' },
                      borderTop: '1px solid #E2E8F0',
                      borderBottom: 'none',
                      height: '28px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {PlayerName?.[partnership.player1]?.name?.firstName ?? ProfileDetails?.[partnership.player1]?.name?.firstName}
                  </TableCell>
                  <Box sx={{ width: '1px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      padding: '1px 2px',
                      width: { xs: '40%', sm: '30%', md: '30%' },
                      borderBottom: 'none',
                      height: '28px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '2px' }}>
                      <Typography variant="body2" sx={{ marginBottom: 0.25 }}>
                        <span style={{ fontWeight: 600, fontSize: '10px' }}>{partnership.runs}</span>
                        <span style={{ fontWeight: 500, marginLeft: '2px', fontSize: '10px' }}>({partnership.balls})</span>
                      </Typography>
                      <Box sx={{ display: 'flex', width: '100%', height: 2, padding: '0px 2px' }}>
                        <Box
                          sx={{
                            width: `${(partnership.player1Runs / partnership.runs) * 100}%`,
                            backgroundColor: '#A7BB3B',
                          }}
                        />
                        <Box
                          sx={{
                            width: `${(partnership.player2Runs / partnership.runs) * 100}%`,
                            backgroundColor: '#3B3B3B',
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <Box sx={{ width: '1px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      textAlign: 'right',
                      padding: '6px 2px',
                      fontSize: '10px',
                      fontWeight: 600,
                      borderBottom: 'none',
                      borderTop: '1px solid #E2E8F0',
                      width: { xs: '25%', sm: '25%', md: '20%' },
                      height: '28px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {partnership.player2 === 'null'
                      ? '--'
                      : PlayerName?.[partnership.player2]?.name?.firstName ??
                        ProfileDetails?.[partnership.player2]?.name?.firstName}
                  </TableCell>
                </Box>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const TopPerformersTable = ({ inningId }) => {
  const { topPerformers } = useSelector((state) => state.match);
  const matchData = useSelector((state) => state.match);
  const over = matchData?.data?.[0]?.info?.[0]?.numOfBallsPerOver;
  const ProfileDetails = matchData?.profileName;

  if (!topPerformers || !Array.isArray(topPerformers)) {
    return null;
  }

  const currentInning = topPerformers.find((inning) => inning.inningId === String(inningId));

  if (!currentInning) {
    return null;
  }

  const formatBatsmanBall = (balls) => {
    const overs = Math.floor(balls / over);
    const remainingBalls = balls % over;
    return `${overs}.${remainingBalls}`;
  };

  return (
    <Box
      sx={{
        margin: '16px',
        borderRadius: '13px',
        boxShadow: '6px 6px 16px 6px #14142B1C',
        overflowX: 'auto', // Ensure horizontal scrolling if content overflows
      }}
    >
      <TableContainer component={Paper} sx={{ padding: '4px 8px' }}>
        <Typography
          sx={{
            padding: 0.5,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '14.06px',
            letterSpacing: '0.5px',
            textAlign: 'left',
            color: '#515153',
          }}
        >
          TOP PERFORMERS
        </Typography>
        <Table sx={{ minWidth: 250 }}>
          <TableHead>
            <TableRow sx={{ borderBottom: 'none', display: 'flex', justifyContent: 'space-between', gap: '2px', width: '100%' }}>
              <TableCell
                sx={{
                  width: '48%',
                  height: '28px',
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px 0px 4px 4px',
                  borderBottom: '2px solid #978FED',
                  textAlign: 'left',
                  bgcolor: '#FFFFFF',
                }}
              >
                Batter
              </TableCell>
              <Box sx={{ width: '1px', bgcolor: '#E2E8F0' }} />
              <TableCell
                sx={{
                  width: '48%',
                  height: '28px',
                  fontSize: '10px',
                  fontWeight: 500,
                  padding: '4px 4px 4px 0px',
                  borderBottom: '2px solid #978FED',
                  textAlign: 'right',
                  bgcolor: '#FFFFFF',
                }}
              >
                Bowler
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentInning.topBatters.map((batter, batterIndex) => (
              <TableRow
                key={batterIndex}
                sx={{ borderBottom: 'none', display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%' }}
              >
                <TableCell
                  sx={{
                    width: '100%',
                    height: '28px',
                    padding: '4px',
                    borderBottom: 'none',
                    borderTop: '1px solid #E2E8F0',
                  }}
                >
                  {batter.batsmanName && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '22px',
                        fontWeight: 600,
                        fontSize: '10px',
                      }}
                    >
                      <span>
                        {ProfileDetails?.[batter.batsmanName]?.name?.firstName?.length > 12
                          ? ProfileDetails[batter.batsmanName].name.firstName.slice(0, 12) + '...'
                          : ProfileDetails[batter.batsmanName].name.firstName}
                      </span>
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <span>{batter.batsmanRun}</span>
                        <span style={{ fontSize: '10px', color: '#64748B' }}>({formatBatsmanBall(batter.batsmanBall)})</span>
                      </Box>
                    </Box>
                  )}
                </TableCell>
                <Box sx={{ width: '1px', bgcolor: '#E2E8F0' }} />
                <TableCell
                  sx={{
                    width: '100%',
                    padding: '4px',
                    borderTop: '1px solid #E2E8F0',
                    borderBottom: 'none',
                  }}
                >
                  {currentInning.topBowlers[batterIndex]?.bowlerName && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '22px',
                        fontWeight: 600,
                        fontSize: '10px',
                      }}
                    >
                      <span>
                        {ProfileDetails?.[currentInning.topBowlers[batterIndex].bowlerName]?.name?.firstName?.length > 12
                          ? ProfileDetails[currentInning.topBowlers[batterIndex].bowlerName].name.firstName.slice(0, 12) + '...'
                          : ProfileDetails[currentInning.topBowlers[batterIndex].bowlerName].name.firstName}
                      </span>
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <span>{currentInning.topBowlers[batterIndex].bowlerRun}</span>
                        <span style={{ fontSize: '10px', color: '#64748B' }}>
                          ({formatBatsmanBall(currentInning.topBowlers[batterIndex].bowlerBall)})
                        </span>
                      </Box>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export { BattingPartnershipTable, TopPerformersTable };
