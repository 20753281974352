import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthWall from './auth/AuthWall';
import { useLocation } from 'react-router-dom';

const AuthWrapper = ({ children}) => {
  const { showAuthWall, isAuthenticated } = useSelector((state) => state.auth);

  const location = useLocation();

  return (
    <>
      {!isAuthenticated && (
        <AuthWall isVisible={showAuthWall} />
      )}
      {children}
    </>
  );
};

export default AuthWrapper;
